import React from "react"
import styled from 'styled-components'
import { down } from 'styled-breakpoints';
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
//DATA
//STYLES
import { H1, H4, H2, H3, H5, LabelBW, Text } from "../utils/typo"
import { ContainerMob, CardWrapper, Section, CardWrapperFull, ContainerHalf, Ratio, ContentRatio } from '../utils/utils'
//COMPONENTS
import SEO from '../components/seo/SEO'
import Layout from '../layout/layout'
import Button from "../components/button/Button";
import AccordionCardCennik from "../components/accordionCard/AccordionIksCennik1";



const Polityka = () => {

    return <div>
        <SEO
            title="Polityka prywatności"
            slug='/polityka-prywatnosci' />
        <Layout>

        <AccordionCardCennik subheading='subheading'>

        </AccordionCardCennik>

        </Layout>
    </div >
}
export default Polityka
